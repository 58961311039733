'use client'
import React, { useState, useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

const SearchComponent = ({ locale }: { locale: string }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslations('SearchComponent');
  const router = useRouter();

  const handleSearch = useCallback(async () => {
    if (searchTerm.trim()) {
      setIsLoading(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        await router.push(`/search-results?q=${encodeURIComponent(searchTerm.trim())}&locale=${locale}`);
      } finally {
        setIsLoading(false);
      }
    }
  }, [searchTerm, router, locale]);

  const isButtonDisabled = useMemo(() => isLoading || !searchTerm.trim(), [isLoading, searchTerm]);

  return (
    <div className="w-full max-w-2xl mx-auto mb-8">
      <form role="search" onSubmit={(e) => { e.preventDefault(); handleSearch(); }} className="flex items-center">
        <div className="relative flex-grow">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t('searchPlaceholder')}
            aria-label={t('searchPlaceholder')}
            className="w-full py-3 px-5 pr-20 text-lg rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
          />
          <button 
            type="submit"
            disabled={isButtonDisabled}
            aria-label={t('searchButton')}
            className="absolute right-1 top-1 bottom-1 px-6 bg-blue-400 text-white rounded-full transition duration-150 ease-in-out hover:bg-blue-500 active:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:bg-blue-200"
          >
            {isLoading ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            ) : t('searchButton')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComponent;