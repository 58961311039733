'use client';

import React from 'react';

interface VisitWebsiteButtonProps {
  link: string;
}

const VisitWebsiteButton: React.FC<VisitWebsiteButtonProps> = ({ link }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <a
      href={link+"?utm_source=aitoolly.com&utm_medium=referral"}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
      className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-blue-300 hover:bg-blue-700 transition duration-300 ease-in-out"
      title="访问网站"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 text-white">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
      </svg>
    </a>
  );
};

export default VisitWebsiteButton;