import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/SearchComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/VisitWebsiteButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/opt/buildhome/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/icon.png");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/public/shipfast_logo.png");
